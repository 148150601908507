export default{
    pointList : [{
        bottom:'217',
        right:'72',
        key:'1',
        centre_x:138,
        centre_y:326,
        index:102
    },{
        bottom:'352',
        right:'258',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:101
    },{
        bottom:'390',
        right:'325',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:100
    },{
        bottom:'426',
        right:'382',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:99
    },{
        bottom:'463',
        right:'438',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:98
    },{
        bottom:'495',
        right:'499',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:97
    },{
        bottom:'536',
        right:'552',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:96
    },{
        bottom:'572',
        right:'609',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:95,
        isSwerve:true
    },{
        bottom:'609',
        right:'552',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:94
    },{
        bottom:'645',
        right:'496',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:93,
        img:require('@/assets/images/light1.png')
    },{
        bottom:'682',
        right:'439',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:92
    },{
        bottom:'718',
        right:'383',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:91
    },{
        bottom:'755',
        right:'326',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:90
    },{
        bottom:'791',
        right:'270',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:89
    },{
        bottom:'828',
        right:'213',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:88
    },{
        bottom:'865',
        right:'157',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:87
    },{
        bottom:'901',
        right:'100',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:86,
        isSwerve:true
    },{
        bottom:'938',
        right:'157',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:85
    },{
        bottom:'974',
        right:'214',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:84
    },{
        bottom:'1011',
        right:'270',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:83,
        img:require('@/assets/images/light2.png')
    },{
        bottom:'1047',
        right:'327',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:82
    },{
        bottom:'1084',
        right:'384',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:81
    },{
        bottom:'1120',
        right:'441',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:80
    },{
        bottom:'1157',
        right:'497',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:79
    },{
        bottom:'1193',
        right:'554',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:78
    },{
        bottom:'1230',
        right:'611',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:77,
        isSwerve:true
    },{
        bottom:'1266',
        right:'555',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:76
    },{
        bottom:'1303',
        right:'499',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:75
    },{
        bottom:'1339',
        right:'442',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:74
    },{
        bottom:'1376',
        right:'386',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:73,
        img:require('@/assets/images/light3.png')
    },{
        bottom:'1413',
        right:'330',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:72
    },{
        bottom:'1449',
        right:'274',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:71
    },{
        bottom:'1486',
        right:'218',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:70
    },{
        bottom:'1522',
        right:'161',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:69
    },{
        bottom:'1559',
        right:'105',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:68
    },{
        bottom:'1595',
        right:'49',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:67,
        isSwerve:true
    },{
        bottom:'1632',
        right:'106',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:66
    },{
        bottom:'1668',
        right:'162',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:65
    },{
        bottom:'1705',
        right:'219',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:64
    },{
        bottom:'1741',
        right:'275',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:63,
        img:require('@/assets/images/light4.png')
    },{
        bottom:'1778',
        right:'332',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:62
    },{
        bottom:'1814',
        right:'389',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:61
    },{
        bottom:'1851',
        right:'445',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:60
    },{
        bottom:'1888',
        right:'502',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:59
    },{
        bottom:'1924',
        right:'558',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:58
    },{
        bottom:'1961',
        right:'615',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:57,
        isSwerve:true
    },{
        bottom:'1997',
        right:'559',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:56
    },{
        bottom:'2034',
        right:'502',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:55
    },{
        bottom:'2070',
        right:'446',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:54
    },{
        bottom:'2107',
        right:'389',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:53,
        img:require('@/assets/images/light5.png')
    },{
        bottom:'2143',
        right:'333',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:52
    },{
        bottom:'2180',
        right:'276',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:51
    },{
        bottom:'2216',
        right:'220',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:50
    },{
        bottom:'2253',
        right:'163',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:49
    },{
        bottom:'2289',
        right:'107',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:47,
        isSwerve:true
    },{
        bottom:'2326',
        right:'164',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:46
    },{
        bottom:'2362',
        right:'220',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:45
    },{
        bottom:'2399',
        right:'277',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:44
    },{
        bottom:'2436',
        right:'333',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:43
    },{
        bottom:'2472',
        right:'390',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:42,
        isSwerve:true,
        img:require('@/assets/images/light6.png')
    },{
        bottom:'2509',
        right:'333',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:41
    },{
        bottom:'2545',
        right:'277',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:40
    },{
        bottom:'2582',
        right:'220',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:39
    },{
        bottom:'2618',
        right:'164',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:38,
        isSwerve:true
    },{
        bottom:'2655',
        right:'222',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:37
    },{
        bottom:'2691',
        right:'279',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:36
    },{
        bottom:'2728',
        right:'337',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:35
    },{
        bottom:'2764',
        right:'395',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:34,
        isSwerve:true
    },{
        bottom:'2801',
        right:'338',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:33
    },{
        bottom:'2837',
        right:'281',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:32,
        img:require('@/assets/images/light7.png')
    },{
        bottom:'2874',
        right:'223',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:31
    },{
        bottom:'2911',
        right:'166',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:30
    },{
        bottom:'2947',
        right:'109',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:29,
        isSwerve:true
    },{
        bottom:'2984',
        right:'166',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:28
    },{
        bottom:'3020',
        right:'223',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:27
    },{
        bottom:'3057',
        right:'280',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:26
    },{
        bottom:'3093',
        right:'338',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:25
    },{
        bottom:'3130',
        right:'395',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:24
    },{
        bottom:'3166',
        right:'452',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:23
    },{
        bottom:'3203',
        right:'509',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:22,
        isSwerve:true,
        img:require('@/assets/images/light8.png')
    },{
        bottom:'3239',
        right:'453',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:21
    },{
        bottom:'3276',
        right:'398',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:20,
        isSwerve:true
    },{
        bottom:'3312',
        right:'456',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:19
    },{
        bottom:'3349',
        right:'512',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:18,
        isSwerve:true
    },{
        bottom:'3385',
        right:'455',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:17
    },{
        bottom:'3422',
        right:'398',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:16
    },{
        bottom:'3459',
        right:'341',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:15
    },{
        bottom:'3495',
        right:'283',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:14
    },{
        bottom:'3532',
        right:'226',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:13
    },{
        bottom:'3568',
        right:'169',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:12,
        img:require('@/assets/images/light9.png')
    },{
        bottom:'3605',
        right:'112',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:11,
        isSwerve:true
    },{
        bottom:'3641',
        right:'169',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:10
    },{
        bottom:'3678',
        right:'225',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:9
    },{
        bottom:'3717',
        right:'282',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:8
    },{
        bottom:'3751',
        right:'338',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:7
    },{
        bottom:'3787',
        right:'395',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:6,
        isSwerve:true
    },{
        bottom:'3824',
        right:'336',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:5
    },{
        bottom:'3860',
        right:'277',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:4,
        isSwerve:true
    },{
        bottom:'3897',
        right:'333',
        key:'1',
        centre_x:0,
        centre_y:0,
        index:3
    },{
        bottom:'3922',
        right:'369',
        key:'1',
        centre_x:393,
        centre_y:4027,
        index:2
    }]
    

} 
